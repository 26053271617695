<template>
  <ValidationObserver ref="form" tag="form" @submit.prevent="submit">
    <v-card-title class="flex-column text-h3 semi-bold mb-6 px-6">
      <v-row no-gutters class="align-self-start">
        <v-col cols="12">
          <app-text-btn
            class="grey--text body-1"
            icon-size="10"
            prepend-icon="icon-chevron-left"
            title="resetPassword.backToLogin"
            @click="$emit('togglePasswordReset')"
          />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-4">
        {{ $t('resetPassword.title') }}
      </v-row>
    </v-card-title>

    <v-card-text class="px-6 body-1">
      <template v-if="!submitted">
        <p>{{ $t('resetPassword.resetPasswordHelp') }}</p>

        <v-row class="mt-4">
          <v-col cols="12">
            <app-text-field v-model="email" label="email" rules="required|email" />
          </v-col>
        </v-row>

        <div class="d-flex justify-end mt-4 mb-3">
          <app-btn type="submit" :loading="loading">
            {{ $t('navigation.send') }}
          </app-btn>
        </div>

        <div class="mt-12 mb-4 subtitle-1 grey--text">{{ $t('resetPassword.passwordHint') }}</div>
      </template>

      <template v-else>
        {{ $t('resetPassword.sentMail') }}
        <div class="d-flex justify-end mt-4 mb-3">
          <app-btn @click="$emit('togglePasswordReset')">
            {{ $t('navigation.toLogin') }}
          </app-btn>
        </div>
      </template>
    </v-card-text>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Authentication/store';

export default {
  name: 'ResetPasswordForm',

  data: () => ({
    email: '',
    loading: false,
    submitted: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['requestPasswordReset']),

    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      this.loading = true;
      const { error } = await this.requestPasswordReset(this.email);

      this.loading = false;
      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
