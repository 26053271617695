<template>
  <app-view-container v-if="!$auth.check()" :background-src="product.background">
    <component :is="form" @togglePasswordReset="resetPassword = !resetPassword" />
  </app-view-container>

  <app-loading-sheet v-else />
</template>

<script>
import LoginForm from '@/modules/Authentication/components/LoginForm';
import RequestPasswordResetForm from '@/modules/Authentication/components/RequestPasswordResetForm';

export default {
  name: 'Login',

  components: {
    LoginForm,
    RequestPasswordResetForm
  },

  computed: {
    resetPassword: {
      get() {
        return this.getQueryParam(this.queryParam.RESET_PASSWORD);
      },
      set(value) {
        this.setQueryParam(this.queryParam.RESET_PASSWORD, value);
      }
    },
    form() {
      if (this.resetPassword) {
        return 'request-password-reset-form';
      }
      return 'login-form';
    }
  }
};
</script>
