<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form" @submit.prevent="login">
    <v-card-title class="text-h3 semi-bold pa-6 pt-2 d-block">
      <partner-logos height="60px" width="60px" class="mt-2 mb-6" />
      <div>
        <h1>
          {{ $t('login.login') }}
        </h1>
        <p>
          {{ $t('login.welcomeBack', { portalName }) }}
        </p>
      </div>
    </v-card-title>

    <v-card-text class="pa-6 pt-4 body-1">
      <v-alert
        :value="error"
        class="mb-4"
        color="error"
        data-cy="login-alert"
        icon="mdi-alert-circle-outline"
        outlined
      >
        <p>{{ $t('login.badCredentials') }}</p>
        <br />
        <p>{{ $t('phone') }}: {{ product.serviceHotline }}</p>
        <p>{{ $t('email') }}: {{ product.serviceMail }}</p>
      </v-alert>

      <v-row no-gutters>
        <v-col cols="12">
          <app-text-field v-model="email" label="emailAddress" mode="lazy" required rules="email" />
        </v-col>

        <v-col cols="12" class="mt-4">
          <app-text-field
            id="current-password"
            v-model="password"
            :append-function="toggleShow"
            :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="current-password"
            label="password"
            required
          />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="text-right mt-1">
          <app-link-btn
            variant="muted"
            title="login.forgotPassword"
            @click="$emit('togglePasswordReset')"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-spacer />

    <v-divider />

    <v-card-actions class="px-6">
      <v-row justify="end">
        <v-col v-if="infoUrl" cols="6" class="d-flex flex-column justify-center align-start">
          <div class="caption">{{ $t('login.interestedInAccount') }}</div>
          <app-link-btn
            :href="infoUrl"
            class="mt-1"
            target="_blank"
            title="login.informNow"
            variant="muted"
          />
        </v-col>

        <v-col cols="6">
          <app-btn
            :disabled="formIncomplete || failed"
            :loading="loading"
            block
            data-cy="login-submit"
            type="submit"
            @click.prevent="login"
          >
            {{ $t('login.login') }}
          </app-btn>
        </v-col>
        <v-col cols="12">
          <v-row justify="end" class="pt-2">
            <v-col cols="auto">
              <app-link-btn
                :href="product.privacyPolicyUrl"
                :title="$t('login.privacyPolicy')"
                target="_blank"
                variant="muted"
              />
            </v-col>
            <v-col cols="auto">
              <app-link-btn
                :href="product.imprintUrl"
                :title="$t('login.imprint')"
                target="_blank"
                variant="muted"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-actions>
  </ValidationObserver>
</template>

<script>
import { ADMIN_REFRESH_TOKEN_KEY, ADMIN_TOKEN_KEY } from '@/plugins/vue-auth';
import PartnerLogos from '@/shared/components/PartnerLogos';

export default {
  name: 'LoginForm',

  components: { PartnerLogos },

  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    error: false,
    loading: false
  }),

  computed: {
    formIncomplete() {
      return !this.email || !this.password;
    },
    infoUrl() {
      if (!this.product) {
        return;
      }

      return this.product.infoUrl;
    }
  },

  methods: {
    async login() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.error = false;
      this.loading = true;

      const redirect = this.$route.query[this.queryParam.REDIRECT];

      return this.$auth
        .login({
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            username: this.email,
            password: this.password,
            product: this.productType
          },
          rememberMe: false,
          redirect: redirect ?? { name: this.routeName.DASHBOARD }
        })
        .then(async (response) => {
          await this.setCurrent(response.data.id);
          this.loading = false;

          if (!this.$auth.check(this.role.ADMIN) || redirect) {
            return;
          }

          return this.$router.push({ name: this.routeName.USERS });
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    }
  },

  created() {
    if (this.admin) {
      this.$auth.token(ADMIN_TOKEN_KEY, null);
      this.$auth.token(ADMIN_REFRESH_TOKEN_KEY, null);
    }

    if (!this.$auth.redirect() || this.$auth.redirect().from.fullPath === '/') {
      return;
    }

    this.$router.replace({
      query: { [this.queryParam.REDIRECT]: this.$auth.redirect().from.fullPath }
    });
  }
};
</script>
